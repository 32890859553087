import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { monolithApi } from '@/utils/api';

export interface UseMonolithApiMutationOptions<ResponseType, ErrorType, RequestDataType>
  extends Omit<UseMutationOptions<AxiosResponse<ResponseType>, AxiosError<ErrorType>, RequestDataType>, 'mutationFn'> {
  requestConfig?: Omit<AxiosRequestConfig, 'url' | 'method' | 'data'>;
}

const useMonolithApiMutation = <ResponseType, ErrorType, RequestDataType>(
  url: string,
  method: Method,
  options?: UseMonolithApiMutationOptions<ResponseType, ErrorType, RequestDataType>,
): UseMutationResult<AxiosResponse<ResponseType>, AxiosError<ErrorType>, RequestDataType> =>
  useMutation({
    mutationFn: (data) => {
      return monolithApi.request({
        url,
        method,
        data,
        ...options?.requestConfig,
      });
    },
    ...options,
  });

export default useMonolithApiMutation;
