import { useEffect } from 'react';
import { Box, Button, ButtonProps, Text } from '@bitrise/bitkit';
import { SocialLoginProviders } from '@/constants';
import { getCsrfTokenCookie } from '@/utils/tokens';
import useQueryParams from '@/hooks/useQueryParams';
import { updateQueryString } from '@/utils/queryParams';
import SocialLoginButton from '../SocialLoginButton/SocialLoginButton';
import HorizontalRulerWithContent from '../../HorizontalRulerWithContent/HorizontalRulerWithContent';

export type LoginButtonGroupProps = {
  signUpMode: boolean;
  onEmailSelect?: () => void;
  ssoButtonProps: ButtonProps & { onClick?: () => void };
  rememberMe?: boolean;
};

const gitProviders: SocialLoginProviders[] = [
  SocialLoginProviders.github,
  SocialLoginProviders.bitbucket,
  SocialLoginProviders.gitlab,
];

const buttonColors: { [key in SocialLoginProviders]?: { borderColor: string; hoverBgColor: string } } = {
  GitHub: {
    borderColor: 'black',
    hoverBgColor: 'neutral.93',
  },
  GitLab: {
    borderColor: 'orange.70',
    hoverBgColor: 'orange.95',
  },
  Bitbucket: {
    borderColor: '#2684ff',
    hoverBgColor: 'blue.95',
  },
};

const LoginButtonGroup = ({
  signUpMode,
  onEmailSelect = () => {},
  ssoButtonProps,
  rememberMe,
}: LoginButtonGroupProps): JSX.Element => {
  const token = getCsrfTokenCookie();
  const params = useQueryParams();
  const useSocialLogin = params.get('use');
  const rememberMePart = typeof rememberMe === 'boolean' ? `?remember_me=${rememberMe}` : '';

  useEffect(() => {
    setTimeout(() => {
      if (!useSocialLogin) {
        return;
      }

      switch (useSocialLogin.toLowerCase()) {
        case 'sso':
          if (ssoButtonProps.onClick) {
            params.delete('use');
            updateQueryString(params, 'replace');
            ssoButtonProps.onClick();
          } else {
            window.location.assign('/users/sign-in/saml');
          }

          return;
        case 'github':
        case 'gitlab':
        case 'bitbucket':
        case 'google':
          document.forms.namedItem(`login_with_${useSocialLogin}`)?.submit();
      }
    }, 300);
  }, [useSocialLogin, params, ssoButtonProps]);

  const beforeText = {
    default: signUpMode ? 'Sign up with' : 'Continue with',
    github: signUpMode ? 'Configure' : 'Continue with',
  };

  const afterText = {
    default: '',
    github: signUpMode ? 'access' : '',
  };

  return (
    <Box display="flex" flexDirection="column" gap="16" backgroundColor="neutral.100">
      <Button
        width="100%"
        variant="secondary"
        leftIconName="Mail"
        _hover={{ borderColor: 'neutral.70', backgroundColor: 'neutral.93' }}
        onClick={onEmailSelect}
      >
        {beforeText.default} Email {afterText.default}
      </Button>

      <SocialLoginButton
        name="login_with_sso"
        provider={SocialLoginProviders.saml}
        {...ssoButtonProps}
        textBefore={beforeText.default}
        width="100%"
        _hover={{ backgroundColor: 'neutral.93' }}
      />

      <Box
        name="login_with_google"
        as="form"
        action={`/users/auth/google${rememberMePart}`}
        method="post"
        flexGrow={1}
        width="100%"
      >
        <input type="hidden" name="authenticity_token" value={token} />
        <SocialLoginButton
          type="submit"
          provider={SocialLoginProviders.google}
          textBefore={beforeText.default}
          width="100%"
          _hover={{ backgroundColor: 'neutral.93' }}
        />
      </Box>

      <HorizontalRulerWithContent>
        <Text margin="8" color="neutral.30">
          Or {signUpMode ? 'sign up' : 'log in'} with your git provider
        </Text>
      </HorizontalRulerWithContent>

      {gitProviders.map((provider) => (
        <Box
          name={`login_with_${provider.toLowerCase()}`}
          as="form"
          key={provider}
          action={`/users/auth/${provider}${rememberMePart}`}
          method="post"
          flexGrow={1}
          width="100%"
        >
          <input type="hidden" name="authenticity_token" value={token} />
          <SocialLoginButton
            type="submit"
            key={provider}
            provider={provider}
            textBefore={provider === SocialLoginProviders.github ? beforeText.github : beforeText.default}
            textAfter={provider === SocialLoginProviders.github ? afterText.github : afterText.default}
            width="100%"
            borderColor={buttonColors[provider]?.borderColor}
            _hover={{ backgroundColor: buttonColors[provider]?.hoverBgColor }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default LoginButtonGroup;
