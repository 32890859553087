import { useEffect, useState } from 'react';
import { QUERY_PARAMS_CHANGED } from '@/utils/queryParams';

const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState<URLSearchParams>(
    new URLSearchParams(typeof window !== 'undefined' ? window.location.search : undefined),
  );

  useEffect(() => {
    const messageListener = (e: MessageEvent<{ type: typeof QUERY_PARAMS_CHANGED }>) => {
      if (e.data.type === QUERY_PARAMS_CHANGED) {
        setQueryParams(new URLSearchParams(window.location.search));
      }
    };
    const popStateListener = () => {
      setQueryParams(new URLSearchParams(window.location.search));
    };

    window.addEventListener('message', messageListener);
    window.addEventListener('popstate', popStateListener);

    return () => {
      window.removeEventListener('message', messageListener);
      window.removeEventListener('popstate', popStateListener);
    };
  }, []);

  return queryParams;
};

export default useQueryParams;
